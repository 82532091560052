import { graphql } from "gatsby"
import React from "react"

import Layout from "../layouts/Layout"
import BlogPostPreview from "../components/BlogPostPreview"
import PageFooter from "../components/PageFooter"
import SiteMetadata from "../components/SiteMetadata"

import { filterByLocale } from "../helpers"

const portfolio = ({ data }) => {
  const currentLocale = "pt-BR"

  const blogPosts = filterByLocale(data.blogPosts, currentLocale)

  return (
    <Layout>
      <SiteMetadata
        title="Blog | Pri Bellafronte"
        description="Dicas e reflexões sobre design gráfico e empreendedorismo para inspirar. Descubra o mundo do design no blog Pri Bellafronte."
      />

      <div>
        <div className="container py-12 lg:pb-16">
          <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-12 text-center">
            Blog
          </h1>

          <div className="flex flex-wrap">
            {blogPosts.length > 0 ? (
              blogPosts.map(blogPost => {
                return (
                  <div
                    className="w-full sm:w-1/2 lg:w-1/3 p-3 md:p-1"
                    key={blogPost.slug}
                  >
                    <BlogPostPreview {...blogPost} />
                  </div>
                )
              })
            ) : (
              <p>No projects found.</p>
            )}
          </div>
        </div>
      </div>

      <div className="py-12 px-4 lg:py-16">
        <PageFooter />
      </div>
    </Layout>
  )
}

export default portfolio

export const query = graphql`
  query BlogPostsQuery {
    blogPosts: allContentfulBlogPost(
      sort: { fields: publishDate, order: DESC }
    ) {
      nodes {
        ...BlogPostPreviewItem
      }
    }
  }
`
